var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"iteration-section-wrapper",class:[{ 'is-story': _vm.isStory }, { 'has-shadow': _vm.format.thumbnailShadow }],on:{"mousedown":function($event){return _vm.$emit('click-done', _vm.videoIndex)},"touchstart":function($event){return _vm.$emit('click-done', _vm.videoIndex)}}},[(_vm.isStory)?_c('div',{class:[
        'solid-border',
        { animate: _vm.clickedInlineIndex === _vm.videoIndex },
      ],style:(`border: 3px solid ${_vm.format.borderColor}`)}):_vm._e(),(_vm.isStory)?_c('div',{class:[
        'dotted-border',
        { animate: _vm.clickedInlineIndex === _vm.videoIndex },
      ],style:(`border: 3px dashed ${_vm.format.borderColor}`)}):_vm._e(),_c('VideoPlayer',{staticClass:"vidjet-carousel-item",style:(`${_vm.makeNonPortraitVideosWider(_vm.videoIndex)};`),attrs:{"video-index":_vm.videoIndex,"clicked-index":_vm.isInlined ? _vm.clickedInlineIndex : 0}})],1),(_vm.isStory && _vm.textOptions.text)?_c('div',{staticClass:"story-title-wrapper"},[_c('h3',{staticClass:"story-title",style:([
        _vm.textOptionsStyle,
        _vm.shouldLowerFontSize,
        { 'font-size': `${_vm.textOptions.size}px` },
      ])},[_vm._v(" "+_vm._s(_vm.decodeText(_vm.textOptions.text))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }