var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"slidingWrapper",class:['sliding-wrapper', { hideArrows: _vm.hideArrows }],style:(_vm.wrapperStyle)},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.isStory && (_vm.numberOfVideosToDisplay < _vm.videos.length || _vm.isInPreview)
    ),expression:"\n      !isStory && (numberOfVideosToDisplay < videos.length || isInPreview)\n    "}],staticClass:"arrow --prev",on:{"click":function($event){return _vm.prevSlide()}}},[(!_vm.hideArrows)?_c('CarouselArrow',{attrs:{"arrow-settings":_vm.formatCarouselObject.arrowSettings}}):_vm._e()],1),_c('div',{ref:"slidingContainer",staticClass:"vidjet-carousel-items",class:[
      { 'is-story': _vm.isStory },
      { 'has-shadow': _vm.format.thumbnailShadow },
    ],style:(`${_vm.gridDisplay};`),on:{"mousedown":_vm.onStart,"mousemove":_vm.onMove,"mouseup":_vm.onEnd,"touchstart":_vm.onStart,"touchmove":_vm.onMove,"touchend":_vm.onEnd}},[_vm._l((_vm.videos),function(video,index){return _c('iteration-section',{key:video.id,staticClass:"video-not-selectable",attrs:{"video-index":index,"clickedInlineIndex":_vm.clickedInlineIndex,"card-height":_vm.cardHeight,"card-width":_vm.cardWidth,"number-of-videos-to-display":_vm.numberOfVideosToDisplay},on:{"click-done":function($event){return _vm.handleMouseDown($event)}}})}),(_vm.hasYoutubeThumbnail)?_c('iteration-section',{key:+1,staticClass:"video-not-selectable",attrs:{"video-index":_vm.videos.length,"clickedInlineIndex":_vm.clickedInlineIndex,"card-height":_vm.cardHeight,"card-width":_vm.cardWidth,"number-of-videos-to-display":_vm.numberOfVideosToDisplay},on:{"click-done":function($event){return _vm.handleMouseDown($event)}}}):_vm._e()],2),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.isStory && (_vm.numberOfVideosToDisplay < _vm.videos.length || _vm.isInPreview)
    ),expression:"\n      !isStory && (numberOfVideosToDisplay < videos.length || isInPreview)\n    "}],staticClass:"arrow --next",on:{"click":function($event){return _vm.nextSlide()}}},[(!_vm.hideArrows)?_c('CarouselArrow',{attrs:{"arrow-settings":_vm.formatCarouselObject.arrowSettings}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }